
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticPathsResult, GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { BreadcrumbJsonLd, NextSeo, NextSeoProps } from 'next-seo';
import { useRouter } from 'next/router';
import { Page as MakeswiftPage, PageProps as MakeswiftPageProps } from '@makeswift/runtime/next';
import { SWRConfig } from 'swr';
import { getBaseUrl } from '@/lib/contentful/utils';
import { client, getPageSnapshot } from '@/lib/makeswift/client';
import '@/lib/makeswift/components';
import { runtime } from '@/lib/makeswift/runtime';
import { getPageLanguageAlternatives } from '@/utils/makeswift/getPageLanguageAlternatives';
import { generateSeoUrl } from '@/utils/seo/generateSeoUrl';
import { getNoIndexValueFromPath } from '@/utils/seo/validateIndexing';
import { useUrl } from '@/utils/url/useUrl';
import { HomepageSEO } from '@/components/HomepageSEO/HomepageSEO';
type ParsedUrlQuery = {
    path?: string[];
};
type Props = MakeswiftPageProps & {
    preview: boolean;
    languageAlternates: NextSeoProps['languageAlternates'];
};
export default function Page({ snapshot, preview, languageAlternates }: Props) {
    const { query, locale } = useRouter();
    const url = useUrl();
    const baseUrl = getBaseUrl(locale);
    const breadcrumbs = query?.path && typeof query?.path === 'string'
        ? [query.path]
        : Array.isArray(query?.path)
            ? query.path
            : [];
    const noIndex = snapshot.document.localizedPages.at(0)?.seo.isIndexingBlocked ??
        snapshot.document.seo.isIndexingBlocked ??
        getNoIndexValueFromPath(url?.pathname);
    return (<SWRConfig value={{
            fetcher: (resource, init) => fetch(resource, init).then((res) => res.json())
        }}>
			<NextSeo canonical={generateSeoUrl(url?.href)} openGraph={{
            url: generateSeoUrl(url?.href)
        }} languageAlternates={languageAlternates} noindex={noIndex}/>

			{breadcrumbs.length === 0 ? (<HomepageSEO baseUrl={baseUrl}/>) : (<BreadcrumbJsonLd itemListElements={[
                { position: 1, name: 'Homepage', item: `${baseUrl}/` },
                ...breadcrumbs?.map((breadcrumb, i, arr) => ({
                    position: i + 2,
                    name: i === 0 && breadcrumb === 'company'
                        ? 'About Us'
                        : breadcrumb.charAt(0).toUpperCase() + breadcrumb.slice(1),
                    item: `${baseUrl}/${arr.slice(0, i + 1).join('/')}/`
                }))
            ]}/>)}

			<MakeswiftPage snapshot={snapshot} runtime={runtime}/>
		</SWRConfig>);
}
async function getStaticProps(ctx: GetStaticPropsContext<ParsedUrlQuery, {
    makeswift: boolean;
}>): Promise<GetStaticPropsResult<Props>> {
    const path = '/' + (ctx.params?.path ?? []).join('/');
    const snapshot = await getPageSnapshot({
        pathname: path,
        isMakeswiftPreview: ctx.previewData?.makeswift == true,
        previewData: ctx.previewData,
        locale: ctx.locale,
        timeout: {
            ms: 10000,
            message: `Timeout request getPageSnapshot for "${path}" – Locale: ${ctx.locale}`
        }
    });
    if (snapshot == null) {
        return { notFound: true, revalidate: 3600 };
    }
    const languageAlternates = await getPageLanguageAlternatives({
        path,
        locale: ctx.locale,
        previewData: ctx.previewData
    });
    return {
        props: { snapshot, preview: Boolean(ctx.preview), languageAlternates },
        revalidate: 3600
    };
}
export async function getStaticPaths(): Promise<GetStaticPathsResult<ParsedUrlQuery>> {
    if (Boolean(process.env.SKIP_BUILD_STATIC_GENERATION)) {
        return {
            paths: [],
            fallback: 'blocking'
        };
    }
    const pages = await client().getPages();
    return {
        paths: pages
            .filter((page) => [
            '/apps',
            '/blog',
            '/events',
            '/articles',
            '/company/leaders',
            '/articles/all',
            '/press',
            '/press/news',
            '/press/releases',
            '/company/leaders',
            '/showcase',
            '/redirects',
            '/theme-store'
        ].includes(page.path) === false)
            .map((page) => ({
            params: {
                path: page.path.split('/').filter((segment) => segment !== '')
            }
        })),
        fallback: 'blocking'
    };
}

    async function __Next_Translate__getStaticProps__19302544c0f__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[[...path]]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19302544c0f__ as getStaticProps }
  